import {
  isRef,
  computed,
  reactive,
  toRefs,
  watch
} from '@vue/composition-api'

const unwrapParams = (params) =>
  isRef(params) ? params.value : params

export default function find(options) {
  const defaults = {
    model: null,
    params: null,
    resultModel: null,
    queryWhen: computed(() => true),
    immediate: true
  }

  const { model, params, queryWhen, resultModel, immediate } = Object.assign(
    {},
    defaults,
    options
  )

  if (!model) {
    throw new Error(
      `No model provided for useApiFind(). Did you define and register it with FeathersVuex?`
    )
  }

  const getFetchParams = (_params) => {
    const params = unwrapParams(_params || options.params);
    return params
  }

  const state = reactive({
    isPending: false,
    error: null,
    items: [],
    pagination: null,
    sum: null,
    result: null,
    latestParams: null
  });

  function findItems(params) {
    params = getFetchParams(params)

    if (params && queryWhen.value) {
      state.isPending = true;

      return model
        .find(params)
        .then(response => {
          const {
            data,
            total,
            sum,
            ...result
          } = response
          state.error = null

          let items = [];
          if (resultModel) {
            for(const item of data) {
              items.push(new resultModel(item));
            }
          } else {
            items = data;
          }

          state.items = items;
          state.pagination = { total }
          state.isPending = false
          state.sum = sum
          state.latestParams = params;
          state.result = result

          return response
        });
    }
  }

  watch(
    () => getFetchParams(),
    () => { findItems(params) },
    { immediate }
  )

  return {
    ...toRefs(state),
    findItems
  }
}