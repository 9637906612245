<template lang="pug">
div(
  class="col-span-1 d-flex flex-column rounded border pa-6 shadow hover:shadow-lg bg-white"
)
  .d-flex.justify-between
    div
      div( class="text-3xl xl:text-5xl text-red-800" ) {{ sum.formatted }}
      .font-semibold.text-gray-500.uppercase.text-xs.pt-2 {{ viewModel.title }}

    div
      v-icon( large ) {{ viewModel.icon }}

  template( v-if="items.length > 0" )
    .d-flex.justify-between
      .font-semibold.text-gray-500.uppercase.text-xs.mt-12 {{ viewModel.colTitle }}
      .font-semibold.text-gray-500.uppercase.text-xs.mt-12 Amount
    v-divider.mt-4

    v-simple-table
      template( v-slot:default )
        tbody
          tr(
            v-for="aggregate in items"
          )
            td( class="w-auto lg:w-1/3 pl-0" ) {{ aggregate.title }}
            td( class="w-auto text-right border-r hidden lg:table-cell" )
              span.inline-block.mr-4.text-gray-500 {{ aggregate.count }}
            td( class="w-auto lg:w-1 text-right" )
              span {{ aggregate.amount | toCurrency }}
          tr(
            v-if="items.length === 0"
          )
            td
              .text-center.text-gray-500.py-4 {{ viewModel.noItemsText }}
</template>

<script>
export default {
  name: 'AggregateSumCard',

  props: {
    viewModel: {
      type: Object,
      required: true,
      default: () => {}
    },
    sum: {
      type: Object,
      required: true,
      default: () => ({ raw: 0, decimal: 0, formatted: '$0.00' })
    },
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  }
}
</script>