import {
  reactive,
  toRefs,
  watch,
  isRef
} from '@vue/composition-api'
import feathersClient from '@/api-client'

export default function find(options) {
  const { FeathersVuexModel: Aggregate } = feathersClient.service('aggregates');
  const {
    organizationId,
    immediate,
    aggregateModels
  } = options;

  if (!aggregateModels || aggregateModels.length === 0) {
    throw new Error('Must have at least one aggregate model');
  }

  const getOrganizationId = () => {
    return isRef(options.organizationId)
      ? organizationId.value : organizationId;
  }

  const state = reactive({
    isPending: true,
    aggregates: {}
  });

  function findItems() {
    const aggregate = new Aggregate({
      organizationId: getOrganizationId(),
      aggregates: aggregateModels
    });

    aggregate
      .create()
      .then(aggregates => {
        state.aggregates = aggregates;
        state.isPending = false;
      })
      .catch(e => { throw new Error(e.message) });
  }

  watch(
    () => getOrganizationId(),
    () => { findItems() },
    { immediate: immediate || getOrganizationId() }
  )

  return {
    ...toRefs(state),
    findItems
  }
}