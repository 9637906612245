<template lang="pug">
div( class="d-flex flex-column rounded border pa-6 shadow hover:shadow-lg bg-white pb-0" )
  .font-semibold.text-gray-500.uppercase.text-xs.pb-4 Issue Expenses by Type
  v-chart(
    :option="pie"
    class="chart"
    :autoresize="true"
    :init-option="initOptions"
  )
</template>

<script>
import { computed } from '@vue/composition-api'
import useApiFind from '@/api/useApiFind'
import { PieChart } from "echarts/charts";

export default {
  name: 'IssueExpenseByTypePieChart',

  components: {
    PieChart
  },

  setup(props, { root: { $FeathersVuex } }) {
    const { Advocacy } = $FeathersVuex.api;
    const initOptions = {
      renderer: "canvas"
    };

    const { items: byType } = useApiFind({
      model: Advocacy,
      params: {
        query: {
          $modify: ['byType']
        }
      }
    })

    const pie = computed(() => {
      return {
        tooltip: {
          trigger: 'item',
          formatter: ({ data }) => {
            return `
              <div class="block">
                <div>${ data.name } - ${ data.label }</div>
                <div>Total: ${ data.amount }</div>
              </div>
            `
          }
        },
        legend: {
          top: '0%',
          left: 'left'
        },
        series: [
          {
            type: 'pie',
            radius: '75%',
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              ...byType.value
            ]
          }
        ]
      }
    })

    return {
      pie,
      initOptions
    }
  }
}
</script>

<style scoped>
  .chart {
    width: 100%;
    height: 350px;
  }
</style>