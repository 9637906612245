<template lang="pug">
.dashboard
  .d-flex.justify-between
    .text-gray-900.text-2xl Dashboard

  .mt-12
    .grid.grid-cols-2.gap-12( v-if="aggregates.Receipts && !isPending" )
      div(
        class="col-span-1 flex-column rounded border pa-6 shadow hover:shadow-lg bg-white"
        :class="{ 'justify-center' : top3Receipts.length === 0 }"
      )
        .d-flex.justify-between
          div
            div( class="text-3xl xl:text-5xl text-green-700" )
              | {{ aggregates.Receipts.sum.formatted }}
            .font-semibold.text-gray-500.uppercase.text-xs.pt-2 Receipts Total
          div
            v-icon( large ) mdi-hand-extended-outline

        .d-flex.justify-between
          .font-semibold.text-gray-500.uppercase.text-xs.mt-12 Donors
          .font-semibold.text-gray-500.uppercase.text-xs.mt-12 Amount
        v-divider.mt-4

        v-simple-table( v-if="!receiptsPending" )
          template( v-slot:default )
            tbody
              tr(
                v-for="receipt in top3Receipts"
              )
                td( class="w-auto lg:w-1/3 pl-0" ) {{ receipt.firstName + ' ' + receipt.lastName }}
                td( class="w-auto text-right border-r hidden lg:table-cell" )
                  span.text-gray-500 {{ $day(receipt.invoiceDate).format('MMM Do') }}
                td( class="w-auto lg:w-1/6 text-right" )
                  | {{ receipt.amount | toCurrency }}
              tr(
                v-if="top3Receipts.length === 0"
              )
                td
                  .text-center.text-gray-500.py-4 No Receipts

      AggregateSumCard(
        v-if="top3CandidateExpenses && !candidateExpensesPending"
        :items="top3CandidateExpenses"
        :sum="aggregates.CandidateExpenses.sum"
        :viewModel="{ title: 'Expense by Org', icon: 'mdi-domain', colTitle: 'Org Types', noItemsText: 'No Expenses' }"
      )

      AggregateSumCard(
        v-if="!isPending"
        :items="[]"
        :sum="aggregates.AdvocacyExpenses.sum"
        :viewModel="{ title: 'Issues Expenses', icon: 'mdi-comment-multiple', colTitle: 'Org Types', noItemsText: 'No Expenses' }"
      )

  .mt-12
    .gap-12.grid.grid-cols-2( v-if="hasValues" )
      ExpenseByPartyPieChart

      ExpenseByTypePieChart

      IssueExpenseByTypePieChart
</template>

<script>
import ExpenseByPartyPieChart from './ExpenseByPartyPieChart.vue'
import IssueExpenseByTypePieChart from './IssueExpenseByTypePieChart.vue'
import ExpenseByTypePieChart from './ExpenseByTypePieChart.vue'
import AggregateSumCard from './AggregateSum.Card.vue'
import useApiFind from '@/api/useApiFind'
import useAggregates from '@/api/useAggregates'
import { computed } from '@vue/composition-api'

export default {
  name: 'OrganizationUserDashboard',

  components: {
    ExpenseByPartyPieChart,
    AggregateSumCard,
    ExpenseByTypePieChart,
    IssueExpenseByTypePieChart
  },


  setup(props, { root: { $FeathersVuex } }) {
    const { Aggregate, Expense, Receipt, Candidate } = $FeathersVuex.api;
    const initOptions = {
      renderer: "canvas"
    };

    const { items: byParty, isPending: byPartyPending } = useApiFind({
      model: Candidate,
      params: {
        query: {
          $modify: ['byParty']
        }
      }
    })

    const { items: top3Receipts, isPending: receiptsPending } = useApiFind({
      model: Receipt,
      params: {
        query: {
          $modify: ['byDonor'],
          $limit: 3
        }
      }
    })

    const hasValues = computed(() => {
      if (!isPending.value) {
        const hasCandidateExpenses = aggregates.value.CandidateExpenses.sum.raw > 0;
        const hasAdvocacyExpenses = aggregates.value.AdvocacyExpenses.sum.raw > 0;

        return !isPending.value && !candidateExpensesPending.value && (hasCandidateExpenses || hasAdvocacyExpenses)
      }

      return false;
    })

    const { items: top3CandidateExpenses, isPending: candidateExpensesPending } = useApiFind({
      model: Expense,
      params: {
        query: {
          $modify: ['byCandidate'],
          assignedTo: 'candidate',
          $limit: 3
        }
      },
      resultModel: Expense
    })

    const { aggregates, isPending } = useAggregates({
      immediate: true,
      aggregateModels: [
        {
          type: 'sum',
          col: 'amount',
          service: 'Expenses',
          name: 'CandidateExpenses',
          conditions: [{
            key: 'status',
            value: ['approved', 'submitted', 'confirmed']
          },
          {
            key: 'assignedTo',
            value: ['candidate']
          }]
        },
        {
          type: 'sum',
          col: 'amount',
          service: 'Expenses',
          name: 'AdvocacyExpenses',
          conditions: [{
            key: 'status',
            value: ['approved', 'submitted', 'confirmed']
          },
          {
            key: 'assignedTo',
            value: ['advocacy']
          }]
        },
        { type: 'sum', col: 'amount', service: 'Receipts' }
      ]
    });

    return {
      aggregates,
      isPending,

      hasValues,

      candidateExpensesPending,
      receiptsPending,
      top3CandidateExpenses,
      top3Receipts,

      byParty,
      byPartyPending,

      initOptions
    }
  }
}
</script>

<style scoped>
.chart {
  width: 325px;
  height: 300px;
}
</style>